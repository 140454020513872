import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <footer className="app-footer">
      <div className="app-container">
        <div className="app-copyright">
          {/* Copyright &copy; {new Date().getFullYear()} HomePro Pest Control */}
        </div>
      </div>
    </footer>
  );
}

export default Footer;
