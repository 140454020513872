import React, { useState, useEffect, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Form,
  Button,
  GridRow,
  Input,
  Icon,
  Message,
  Modal,
  FormGroup,
} from "semantic-ui-react";
import { useContext } from "react";
import { AppContext } from "../../../Context/AppContext";
import ADD_UNIT from "./cmp-add-unit/cmp-add-unit";
import "./OrderRequestForm.css";
import { portalReadPdf,treatmentPrice,getSingleBuilding } from "../../../constants";
import MDL_PROGRESS from "../../../components/cmp-progress/cmp-progress";
import { useToasts } from "react-toast-notifications";


// const orderlist =[{
//     "id": 1,
//     "unit_number": "103",
//     "treatment": "Spray Treatment",
//     "pest": "Ants",
//     "description": "Order 1",
//     "price": 100,
//     "quantity": 1,
//     "total": 100
// },
// {
//     "id": 2,
//     "unit_number": "102",
//     "treatment": "Inspection",
//     "pest": "Ants",
//     "description": "Order 2",
//     "price": 200,
//     "quantity": 1,
//     "total": 200
// },
// {
//     "id": 3,
//     "unit_number": "101",
//     "treatment": "Bait Treatment",
//     "pest": "Ants",
//     "description": "Order 3",
//     "price": 300,
//     "quantity": 1,
//     "total": 300
// },
// {
//     "id": 4,
//     "unit_number": "100",
//     "treatment": "Gel and Dust Treatment",
//     "pest": "Bed Bugs",
//     "description": "Order 4",
//     "price": 400,
//     "quantity": 1,
//     "total": 400
// }
// ]
// const OrderSummary = () => {
//   // Step 1: Calculate total sum of prices
//   const totalSum = orderlist.reduce((acc, order) => acc + order.total, 0);

//   // Step 2: Add 13% tax
//   const tax = 0.13;
//   const totalWithTax = totalSum + totalSum * tax;

//   // Return array of objects with total and taxPrice
//   return [{ total: totalWithTax, taxPrice: totalSum * tax }];
// };
function OrderRequestForm() {
  const fileInputRef = useRef(null);
  /* VARIABLE DECLARATIONS
	---------------------------------------------------------------------------------*/
  const {
    bInfo,
    selected_units,
    building_order_details,
    error_validation,
    userInfo,
    cartItems,
    UncheckedUnitsData,
    checkedUnitsData,
    orderPriceSummary,
    ShowHeaderSearch,
    buildingUnits
    // selectedBuilding,
  } = useContext(AppContext);
  const [user] = userInfo;
  const [building_units,setBuildingUnits] = buildingUnits;
  const [selected_building, setSelectedBuilding] = useState({});
  const [pestTreated, setPestTreated] = useState();
  const [form_validation_error, setFormError] = error_validation;
  const [treatmentOption, setTreatmentOptions] = useState();
  const [error, setError] = useState(false);
  const [var_add_unit, set_add_unit] = selected_units;
  const [building_info, setBuildingInfo] = bInfo;
  const [building_order, setBuildingOrder] = building_order_details;
  const [var_ShowHeaderSearch, setShowHeaderSearch]=ShowHeaderSearch;
  const [var_mdl_progress, set_mdl_progress] = useState(false);
  const history = useHistory();
  const [cart_items, setCartItems] = cartItems;
  const [UncheckedUnits, setUnCheckedUnits] = UncheckedUnitsData;
  const [checkedUnits, setCheckedUnits] = checkedUnitsData;
  const { locationID } = useParams();
  const [selectFile, setSelectFile] = useState(null);
  const [suitPostData, setSuitPostData] = useState(null);
  const [filteredArray,setfilteredArray] = useState([])
  const { addToast } = useToasts();
  const [orderSummary,setorderSummary] = orderPriceSummary;
  const prevVarAddUnitRef = useRef([]);

  /* EVENT LISTENERS
	---------------------------------------------------------------------------------*/
  useEffect(() => {
    if (var_add_unit.length) {
      // Check if the array contains only one element with treatment as null
      if (var_add_unit.length === 1 && var_add_unit[0].treatment === null) {
        setorderSummary([]);
      } else {
        // Filter items that have a treatment before summing up
        const filteredUnits = var_add_unit.filter(order => (order.treatment !== undefined && order.treatment !== null));
        setfilteredArray(filteredUnits);
        const totalSum = filteredUnits.reduce((acc, order) => acc + order.price, 0);
  
        const tax = 0.13;
        const totalWithTax = totalSum + totalSum * tax;
  
        if (!isNaN(totalWithTax)) {
          setorderSummary([{ total: totalWithTax,subtotal:totalSum, taxPrice: totalSum * tax }]);
        }
      }
    }
  }, [var_add_unit]);
  


  useEffect(() => {
    // Find new or updated entries
    const newOrUpdatedEntries = var_add_unit.filter((currentItem) => currentItem.fetch_price === true);

    // Fetch prices for each new or updated entry
    newOrUpdatedEntries.forEach((entry) => {
      
      if (entry.treatment) {
        fetchTreatmentPrice(entry);
      }
    });

    // Update the previous state reference
    prevVarAddUnitRef.current = var_add_unit;
  }, [var_add_unit,selected_building]);

  useEffect(() => {
    if (building_info && building_info.length) {
      const sBData = building_info.filter((b) => b.location_id == locationID);
      setSelectedBuilding(sBData?.length && sBData[0]);
      var temp_b_order = { ...building_order };
      temp_b_order = {
        ...temp_b_order,
        building_name: (sBData && sBData[0]?.text) || "",
      };
      setBuildingOrder(temp_b_order);
    } else {
      history.goBack();
    }
  }, [building_info]);

  useEffect(()=>{
    if(building_units.length === 0){
      getBuildingUnits()
    }
  },[building_units])

  useEffect(() => {
    if (UncheckedUnits && UncheckedUnits !== "All") {
      // Create a copy of checkedUnits object
      const updatedCheckedUnits = { ...checkedUnits };
      // Extract floor and unit from UncheckedUnits
      const [floor, unit] = UncheckedUnits.split("-");
      // Construct the key for the unit
      const unitKey = `${floor}-${unit}`;
      // Delete the unit from the object
      delete updatedCheckedUnits[unitKey];
      // Set the updated object to state
      //getBuildingHeatmap()
      setCheckedUnits(updatedCheckedUnits);
      setUnCheckedUnits(null);
    } else if (UncheckedUnits == "All") {
      setCheckedUnits({});
      setUnCheckedUnits(null);
    }
  }, [UncheckedUnits, checkedUnits]);

  //This effect job is for suitspot
  // useEffect(() => {
  //   if (selected_building) {
  //     checkIsSuitSpot();
  //   }
  // }, [selected_building]);

  /* EVENT FUNCTIONS
	---------------------------------------------------------------------------------*/
  const fetchTreatmentPrice = async (entry) => {
    try {
      const tax = 0.13;
      const response = await fetch(treatmentPrice+'?building_uuid='+selected_building.value+'&unit_number='+entry.unit_number+'&pest='+entry.pest+'&treatment='+entry.treatment+'&pestID='+entry.pest_id+'&treatmentID='+entry.treatment_id);
      const result = await response.json();
      // Assuming result contains { price: <price> }
      if (result.data.price !== undefined) {
        const priceWithTax = result.data.price * tax;
        // Update only the specific object with the returned price
        set_add_unit((prevState) =>
          prevState.map((item) =>
            item.key === entry.key ? { ...item, price: result.data.price, fetch_price: false,price_tax:priceWithTax } : item
          )
        );
      }else{
        set_add_unit((prevState) =>
          prevState.map((item) =>
            item.key === entry.key ? { ...item, price: 0, fetch_price: false,price_tax:0 } : item
          )
        );
      }
    } catch (error) {
      console.error('Error fetching treatment price:', error);
    }
  };
  
  
  const onChangeInput = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    if (name === "po_mo_wo") {
      value = value.replace(/^\s+/g, "");
    }
    setBuildingOrder({ ...building_order, [name]: value });
  };

  function checkIsSuitSpot() {
    if (
      selected_building.customer_name == "Park Property GTA" ||
      selected_building.customer_name == "Park property GTA"
    ) {
      setSelectFile(true);
    } else {
      setSelectFile(false);
    }
  }

  async function getBuildingUnits(selected_id) {
    await fetch(
      `${getSingleBuilding}${selected_id || selected_building?.value}`
    )
      .then((response) => response.json())
      .then((response) => {
        const final_data = response.data[0];
        setBuildingUnits(final_data?.units ? final_data?.units : []);
      });
  }

  const add_unit = () => {
    setFormError([...form_validation_error, {}]);
    set_add_unit([
      ...var_add_unit,
      { unit_number: "",followup: "neworder", key: var_add_unit.length },
    ]);
  };

  function pushRoute(isDraft = false) {
    var isValid = true;
    var_add_unit.map((value, i) => {
      if (
        (selected_building.building_po_mo_wo && !building_order.po_mo_wo) ||
        form_validation_error[i]?.unit_number ||
        !value.unit_number ||
        !value.pest_id ||
        !value.treatment_id ||
        !value.followup
      ) {
        isValid = false;
        addToast("Please fill all required(*) fileds .", {
          appearance: "error",
          autoDismiss: true,
        });
      }
    });
    if (isValid) {
      ///technician-home/order-review
      if (user.role === "technician") {
        history.push("/technician-home/order-review");
      } else {
        history.push("/order-review", { isDraft });
      }
    } else {
      setError(true);
    }
  }

  const handleDeleteItem = async (itemKey, index, unit_number) => {
    // Make a copy of cart_items
    const updatedCartItems = { ...cart_items };
  
    // Find the item by unit_number and remove it
    const itemIndex = updatedCartItems[itemKey].findIndex(
      (item) => item.unit_number === unit_number
    );
  
    if (itemIndex !== -1) {
      updatedCartItems[itemKey].splice(itemIndex, 1);
    }
  
    // If the array becomes empty after deletion, remove it from cart_items
    if (updatedCartItems[itemKey].length === 0) {
      delete updatedCartItems[itemKey];
    }
  
    // Set unCheckedUnits after the item has been removed
    const DeleteUnitData = itemKey + "-" + unit_number;
    setUnCheckedUnits(DeleteUnitData);
    
    // Check if updatedCartItems is empty
    if (Object.keys(updatedCartItems).length === 0) {
      // Handle the case when cart_items becomes empty
      setCartItems(null);
    } else {
      // Update the state with the new cart_items
      setCartItems({ ...updatedCartItems });
    }
  };

  const doDelete = (index) => {
    var items = [...var_add_unit];
    if (items[index].floor) {
      handleDeleteItem(items[index].floor, index, items[index]?.unit_number);
    }
    items.splice(index, 1);
    items.map((val, index) => {
      var temp = val;
      temp.key = index;
      items[index] = temp;
    });

    set_add_unit(items);
  };

  const onChangeFile = async (event) => {
    const file = event.target.files[0];
    if (file) {
      set_mdl_progress(true);
      let data = new FormData();
      data.append("document", file);
      data.append("buildingAddress", selected_building?.text);
      fetch(portalReadPdf, {
        method: "POST",
        body: data,
      })
        .then((res) => res.json())
        .then((response) => {
          set_mdl_progress(false);
          if (response.code == 200 && response.data) {
            let { buildingAddress, unitNumber, poNumber } = response.data;

            if (
              buildingAddress.includes(selected_building?.text) ||
              selected_building?.text.includes(buildingAddress)
            ) {
              setBuildingOrder({
                ...building_order,
                po_mo_wo: poNumber.replace(/^\s+/g, ""),
              });

              var temp = [...var_add_unit];
              temp[0] = { ...temp[0], unit_number: unitNumber };
              set_add_unit(temp);
              setSuitPostData(response.data);
            } else {
              addToast("This document is not related to selected building.", {
                appearance: "error",
                autoDismiss: true,
              });
            }
          } else {
            addToast(response?.message, {
              appearance: "error",
              autoDismiss: true,
            });
          }
        })
        .catch((error) => {
          set_mdl_progress(false);
          console.log("Error:::", error);
        });
    }
  };

  /* RENDER APP
	---------------------------------------------------------------------------------*/

  return (
    <div className="app-content">
      <div className="order-request-form-container">
        <div className="app-backto-prev-page">
          <div
            className="app-goback-button"
            onClick={() => {
              setFormError([{}]);
              //setBuildingOrder({});
              set_add_unit([{ unit_number: "", followup: false, key: 0 }]);
              setShowHeaderSearch(!var_ShowHeaderSearch);
              setorderSummary([]);
              history.goBack();
            }}
          >
            <Icon name="arrow left" /> Go Back
          </div>
        </div>
        <div className="order-card-row">
          <div className="order-details-container">
            <div className="order-app-card">
              <Form error={error} >
                <div className="app-card">
                  <div className="app-card-header">
                    <div className="app-card-title">Service Request</div>
                  </div>
                  <div className="app-card-body">
                    <Form.Group>
                      <Form.Field
                        label="Location"
                        width={8}
                        control={Input}
                        required
                        value={selected_building?.text}
                      />
                      {selectFile && (
                        <Form.Field
                          id="upload_file"
                          name="upload_file"
                          label="Please upload PO/MO"
                          control={Input}
                          width={8}
                          type="file"
                          onChange={onChangeFile}
                        />
                      )}
                      {
                        //    selected_building && selected_building.building_po_mo_wo?
                        <Form.Field
                          disabled={selectFile}
                          label={
                            selected_building.building_po_mo_wo?.toLowerCase() ===
                            "wo"
                              ? "Work Order Number"
                              : selected_building?.building_po_mo_wo?.toLowerCase() ===
                                "mo"
                              ? "Maintenance Order Number"
                              : "Purchase Order Number"
                          }
                          width={8}
                          control={Input}
                          required={selected_building?.building_po_mo_wo}
                          name="po_mo_wo"
                          id="po_mo_wo"
                          value={building_order.po_mo_wo}
                          placeholder={`${
                            selected_building?.building_po_mo_wo?.toUpperCase() ||
                            "PO "
                          } number must be entered here before submitting request`}
                          onChange={onChangeInput}
                          maxLength={30}
                        />
                        // :null
                      }
                    </Form.Group>
                  </div>
                </div>
                <div className="add-unit-container">
                  {var_add_unit?.map((unit, index) => (
                    <ADD_UNIT
                      key={index}
                      index={index}
                      width={8}
                      treatment={treatmentOption}
                      pest={pestTreated}
                      editable_unit={selectFile}
                      doDelete={doDelete.bind(this, index)}
                      isTech={user.role === "technician"}
                      pdfData={suitPostData}
                    />
                  ))}
                </div>
                <Message
                  error
                  header="Required Fields"
                  content="Please fill all required(*) fileds"
                />
                <div className="finish-request-actions">
                  {!selectFile && (
                    <>
                      <Button
                        className="add-unit-button"
                        color="grey"
                        type="button"
                        onClick={() => add_unit()}
                      >
                        <img src="/add-square.svg" alt="" />
                        Add Unit
                      </Button>
                    </>
                  )}
                </div>
              </Form>
            </div>
          </div>
          <div className="order-summary-container">
            <div className="order-app-card">
              {/* <div className="app-card-header">
                <div className="app-card-title"></div>
                </div> */}
                <div className="app-card-body">
                {orderSummary.length > 0 && filteredArray.length > 0 && 
                  <>
                  <div className="order-list-header">
                    <div className="list-header-one">Order Summary</div>
                    <div className="list-header-two">Price</div>
                  </div>
                  <span style={{borderTop:"1px solid",width:"100%",display:"block",marginBottom:"10px"}}></span>
                  </>
                }
                
                  <div className="order-list-items" >
                    {var_add_unit && var_add_unit.map((item, index) => (
                        <>
                        {item?.treatment && 
                        <div className="order-list-item" key={index} >
                        <span className="order-title">
                          {item.unit_number} - {item.pest} - {item.treatment} : 
                        </span>
                        <span className="order-price" >
                          ${item.price}
                        </span>
                        </div>
                        }
                        </>
                    ))}
                    {orderSummary.length > 0 && filteredArray.length > 0 && 
                    <>
                    <span style={{borderTop:"1px solid",width:"100%",display:"block",marginBottom:"10px"}}></span>
                    <div className="total-area">
                      <div className="total-area-one"></div>
                      <div className="total-area-two">
                          <div className="order-tax"><span style={{fontWeight:"bold"}}>SUBTOTAL : </span> <span style={{float:"right"}}>${orderSummary[0]?.subtotal?.toFixed(2)}</span></div>
                          <div className="order-tax"><span style={{fontWeight:"bold"}}>TAX : </span> <span style={{float:"right"}}>${orderSummary[0]?.taxPrice?.toFixed(2)}</span></div>
                          <div className="order-tax"><span style={{fontWeight:"bold"}}>TOTAL : </span> <span style={{float:"right"}}>${orderSummary[0]?.total?.toFixed(2)}</span></div>
                      </div>
                    </div>
                    </>
                    }
                    {filteredArray.length === 0 &&
                    <>
                    <GridRow columns={5}  style={{ maxHeight: '730px',overflowY:"auto", height:'50%'}}>
                    <div
                      style={{
                        fontSize: "16px",
                        backgroundColor: "white",
                        color: "#000000",
                        fontWeight: 700,
                        textAlign: "center"
                      }}
                    >
                    <img src="/no-data.png" alt="icon" />
                    </div>
                    </GridRow>
                    </>
                    }
                  </div>
                </div>
              </div>
              <div className="finish-request-actions">
                  {!selectFile && (
                    <>
                      <Button color="orange" onClick={() => pushRoute(true)}>
                        Save as draft
                      </Button>
                    </>
                  )}
                  <Button color="red" onClick={() => pushRoute()}>
                    Finish Request
                  </Button>
                </div>
          </div>
        </div>
      </div>
      {/***** MODAL: PROGRESS **************************************************************************/}
      <Modal
        id="mdl-progress"
        dimmer={"inverted"}
        open={var_mdl_progress}
        closeOnDimmerClick={false}
        closeOnEscape={false}
        onClose={() => {}}
      >
        <MDL_PROGRESS
          set_mdl_open={set_mdl_progress}
          var_modaltitle="Please wait"
          var_message={"Please wait..."}
        ></MDL_PROGRESS>
      </Modal>

      {/***** END MODAL: PROGRESS **************************************************************************/}
    </div>
  );
}

export default OrderRequestForm;
