import React, { useState, useEffect, useContext } from "react";
import {
  Form,
  Input,
  Icon,
  Modal,
  Message,
  FormField,
} from "semantic-ui-react";
import { getAllPests, getAllPestsTreatment,getUnitServiceDetails } from "../../../../constants";
import { AppContext } from "../../../../Context/AppContext";
import "./cmp-add-unit.css";

const ADD_UNIT = ({ editable_unit, index, doDelete,doEdit, isTech, pdfData }) => {
  /* VARIABLE DECLARATIONS
	---------------------------------------------------------------------------------*/
  const [editable_pest, set_editable_pest] = useState(true);
  const [editable_treatment, set_editable_treatment] = useState(true);
  const { selected_units, error_validation, bInfo, userInfo, buildingUnits } =
    useContext(AppContext);
  const [building_info] = bInfo;
  const [user, setUser] = userInfo;
  const [building_units] = buildingUnits;
  const [var_add_unit, set_add_unit] = selected_units;
  const [form_validation_error, setFormError] = error_validation;
  const [order_types, set_order_types] = useState([]);
  const [pestTreated, setPestTreated] = useState();
  const [treatmentOption, setTreatmentOptions] = useState();
  const [units_lookup, set_units_lookup] = useState([]);
  const [unit_data, set_unit_data] = useState();
  const [unit_message, set_unit_message] = useState(false);

  /* ASYNC FUNCTIONS
	---------------------------------------------------------------------------------*/

  useEffect(() => {
    const { role } = user;
    //if(var_add_unit[index].followup)
    if (role === "technician") {
      if (
        var_add_unit[index].followup === false ||
        var_add_unit[index].followup
      ) {
        var_add_unit[index].followup = var_add_unit[index].followup;
      } else var_add_unit[index].followup = "followup";
      set_order_types([
        {
          key: "followup",
          text: "Follow-up (2 weeks)",
          value: "followup",
        },
        {
          key: "reschedule",
          text: "Reschedule",
          value: "reschedule",
        },
      ]);
    } else {
      set_order_types([
        {
          key: "neworder",
          text: "New",
          value: "neworder",
        },
        {
          key: "followup",
          text: "Follow Up (Service In 2 Weeks)",
          value: "followup",
        },
        {
          key: "followup_month",
          text: "Follow Up (Service In Month)",
          value: "followup_month",
        },
        {
          key: "followup_immediately",
          text: "Follow Up (Service Immediately)",
          value: "followup_immediately",
        },
        {
          key: "reschedule",
          text: "Reschedule",
          value: "reschedule",
        },
      ]);
    }
    getPests();
  }, []);

  useEffect(() => {
    getPestTreatments();
  }, [var_add_unit[index].pest_id]);

  useEffect(() => {
    if (editable_unit && treatmentOption?.length > 0 && pdfData) {
      let { treatment } = pdfData;

      if (treatment && treatment?.uuid) {
        set_editable_treatment(false);
        var temp = [...var_add_unit];
        temp[index] = {
          ...temp[index],
          treatment_id: treatment?.uuid,
          treatment: treatment?.treatment_name,
        };
        set_add_unit(temp);
      }
    }
  }, [pdfData, treatmentOption]);

  useEffect(() => {
    if (editable_unit && pestTreated?.length > 0 && pdfData) {
      let { pest } = pdfData;

      if (pest && pest?.uuid) {
        set_editable_pest(false);
        var temp = [...var_add_unit];
        temp[index] = { ...temp[index], pest_id: pest.uuid };
        temp[index] = {
          ...temp[index],
          pest: pest.pest_name,
        };
        temp[index] = { ...temp[index], treatment_id: null };
        temp[index] = { ...temp[index], treatment: null };
        set_add_unit(temp);
      }
    }
  }, [pdfData, pestTreated]);

  useEffect(() => {
    if(building_units){
      prepareUnitsLookup();
    }
  }, [var_add_unit,building_units]);

  useEffect(() => {
    if (var_add_unit[index]?.unit_number) {
      getUnitServiceData(var_add_unit[index]?.unit_number)
    }
  }, [var_add_unit[index].unit_number]);

  function prepareUnitsLookup() {
    let temp_lookup = [];
    building_units.map((val) => {
      temp_lookup = [
        ...temp_lookup,
        { ...val, text: val.unit, value: val.unit },
      ];
    });
    set_units_lookup(temp_lookup);
  }

  async function getPestTreatments() {
    await fetch(getAllPestsTreatment + var_add_unit[index].pest_id)
      .then((response) => response.json())
      .then((res) => {
        var allTempPest = [];
        if (res.code === 200) {
          res.data.map((value, i) => {
            allTempPest = [
              ...allTempPest,
              {
                key: value.treatment_name,
                text: value.treatment_name,
                value: value.treatment_id,
              },
            ];
          });
          setTreatmentOptions(allTempPest);
        } else {
        }
      });
  }
  async function getPests() {
    await fetch(getAllPests)
      .then((response) => response.json())
      .then((res) => {
        var allTempPest = [];
        if (res.code === 200) {
          res.data.map((value, i) => {
            allTempPest = [
              ...allTempPest,
              {
                key: value.pestName,
                text: value.pestName,
                value: value.pestId,
              },
            ];
          });
          setPestTreated(allTempPest);
        } else {
        }
      });
  }

  function updateStaeValues(name, value, isFound) {
    var temp = [...var_add_unit];
    if (name === "unit_number") {
      temp[index] = { ...temp[index], exist: isFound };
    }
    temp[index] = { ...temp[index], [name]: value, fetch_price: true };
    set_add_unit(temp);
  }

  /* EVENT FUNCTIONS
	---------------------------------------------------------------------------------*/
  const onChangeInput = (event) => {
    const { role } = user;
    const units = building_units;
    let name = event.target.name;
    let value = event.target.value;
    var isFound = false;
    doEdit(var_add_unit[index].key);
    if (name === "unit_number") {
      units.map((val) => {
        if (
          value.replace(/^[.\s]+|[.\s]+$/g, "").toLowerCase() ==
          val.unit.toLowerCase()
        ) {
          isFound = true;
        }
      });
      if (role === "technician") {
        if (!isFound) {
          form_validation_error[index].unit_number =
            value + " unit not registered.";
        } else {
          form_validation_error[index].unit_number = null;
        }
      }
    }
    if(value && name === "unit_number"){
      getUnitServiceData(value);
    }
    updateStaeValues(name, value, isFound);
    //set_unit_data({...temp, [name]: value});
  };

  const OnChangePest = (e, { value }) => {
    if (e.target.id === "") {
      doEdit(var_add_unit[index].key);
      var temp = [...var_add_unit];
      temp[index] = { ...temp[index], pest_id: value };
      temp[index] = {
        ...temp[index],
        pest: pestTreated.filter((val) => val.value === value)[0].text,
      };
      temp[index] = { ...temp[index], treatment_id: null };
      temp[index] = { ...temp[index], treatment: null };
      temp[index] = { ...temp[index], fetch_price: false };
      set_add_unit(temp);
    }
  };

  const OnChangeTreatment = (e, { value }) => {
    if (e.target.id === "") {
      doEdit(var_add_unit[index].key);
      var temp = [...var_add_unit];
      temp[index] = { ...temp[index], treatment_id: value };
      temp[index] = {
        ...temp[index],
        treatment: treatmentOption.filter((val) => val.value === value)[0].text,
      };
      temp[index] = { ...temp[index], fetch_price: true };
      set_add_unit(temp);
    }
  };

  const onChangeOrderType = (e, { value }) => {
    if (e.target.id === "") {
      doEdit(var_add_unit[index].key);
      updateStaeValues("followup", value);
    }
  };

  function onChangeUnits(event, { value }) {
    var isFound = false;
    doEdit(var_add_unit[index].key);
    building_units.map((val) => {
      if (
        value.replace(/^[.\s]+|[.\s]+$/g, "").toLowerCase() ==
        val.unit.toLowerCase()
      ) {
        isFound = true;
      }
    });
    if(value){
      getUnitServiceData(value);
    }
    updateStaeValues("unit_number", value, isFound);
  }

  async function getUnitServiceData(unit_number) {
    await fetch(getUnitServiceDetails +building_info[0]?.value+'?unit='+ unit_number)
     .then((response) => response.json())
     .then((res) => {
        if (res.code === 200) {
          set_unit_data(res?.data);
          set_unit_message(true);
        } else {
          set_unit_data(null);
        }
      });
  }

  /* RENDER APP
	---------------------------------------------------------------------------------*/
  return (
    <div className="app-card add-unit-card">
      <Form.Group>
       
        
      <div className="four wide field" style={{display:'flex', flexDirection:'column',gap:'8px',paddingLeft:'0px'}}>
      <div className="unit-label" style={{display:"flex",alignItems:"left",maxHeight:'17px',color:'#4c4c4c',fontWeight:'600',fontSize:'13px'}}>
          <span style={{marginRight:"40px"}}>Unit #<span style={{color:"red"}}>*</span></span>
        {!editable_unit && (
          <>
          <label className="align-center" style={{marginRight:"4px"}}>Manual Entry</label>
            <Form.Checkbox
              checked={var_add_unit[index].extraUnits}
              className="extra-checkbox"
              toggle
              style={{width:'48px',margin:'0px'}}
              onChange={(e, data) => {
                var temp = [...var_add_unit];
                temp[index] = {
                  ...temp[index],
                  extraUnits: data.checked,
                  unit_number: "",
                };
                set_add_unit(temp);
              }}
            />
            </>
        )}
        </div>
        {editable_unit && (
          <Form.Field
            fluid
            id={"unit_number" + index}
            name="unit_number"
            required
            disabled={editable_unit}
            error={form_validation_error[index]?.unit_number}
            value={
              var_add_unit[index].unit_number &&
              var_add_unit[index].unit_number != ""
                ? var_add_unit[index].unit_number
                : ""
            }
            placeholder="Write unit #"
            control={Input}
            onChange={onChangeInput}
          />
        )}
        {!editable_unit && var_add_unit[index].extraUnits && (
          <Form.Field
            fluid
            width={16}
            id={"unit_number" + index}
            name="unit_number"
            required
            disabled={editable_unit}
            error={form_validation_error[index]?.unit_number}
            value={
              var_add_unit[index].unit_number &&
              var_add_unit[index].unit_number != ""
                ? var_add_unit[index].unit_number
                : ""
            }
            placeholder="Write unit #"
            control={Input}
            onChange={onChangeInput}
          />
        )}
        {!editable_unit && !var_add_unit[index].extraUnits && (
          <Form.Select
            search
            required
            width={16}
            id={"unit_number" + index}
            name="unit_number"
            placeholder="Write unit #"
            disabled={editable_unit}
            error={form_validation_error[index]?.unit_number}
            value={
              var_add_unit[index].unit_number &&
              var_add_unit[index].unit_number != ""
                ? var_add_unit[index].unit_number
                : ""
            }
            fluid
            noResultsMessage={"Unit# not found please enable manual entry"}
            onSearchChange={onChangeInput}
            onChange={onChangeUnits}
            options={units_lookup}
          />
          
        )}
        {unit_message && unit_data?.unitDetails && <span style={{marginLeft:'6px',color:' #4c4c4c',fontSize: '13px',fontWeight: '600',marginBottom: '6px'}}>{`Last Service: ${unit_data?.unitDetails}`}</span>}
        </div>

        <Form.Select
          search
          label="Pest Requested"
          name="pest_id"
          id="pest_id"
          width={3}
          placeholder="Select pest"
          selectOnBlur={false}
          disabled={!editable_pest}
          value={
            var_add_unit[index].pest_id ? var_add_unit[index].pest_id : null
          }
          options={pestTreated}
          onChange={OnChangePest}
          required
        />
        <Form.Select
          label="Treatment Requested"
          name="treatment_id"
          id="treatment_id"
          placeholder="Select treatment"
          options={treatmentOption}
          selectOnBlur={false}
          width={4}
          disabled={!editable_treatment}
          value={
            var_add_unit[index].treatment_id
              ? var_add_unit[index].treatment_id
              : null
          }
          onChange={OnChangeTreatment}
          required
        />
         <Form.Select
          label="Type of Request"
          name="order_type"
          width={3}
          id="order_type"
          placeholder="Select type of request"
          options={order_types}
          selectOnBlur={false}
          value={
            var_add_unit[index].followup ? var_add_unit[index].followup : null
          }
          onChange={onChangeOrderType}
          required
        />
        <Form.TextArea
          id="order_description"
          name="order_description"
          label="Notes"
          width={4}
          value={
            var_add_unit[index].order_description
              ? var_add_unit[index].order_description
              : ""
          }
          required={
            var_add_unit[index].pest === "Unknown" ||
            var_add_unit[index].pest === "Other Pest"
          }
          placeholder="Please add any additional info for this unit."
          fluid
          onChange={onChangeInput}
        />
      </Form.Group>
 
      {var_add_unit.length > 1 && (
        <div
          className="icon-remove-unit"
          onClick={() => doDelete(var_add_unit[index].key)}
        >
          <Icon name="times circle outline" />
        </div>
      )}
    
    </div>
    
  );
};
export default ADD_UNIT;
