import React, { useContext, useState, useEffect } from "react";
import { useHistory,Route, Switch, useLocation } from "react-router-dom";
import { Dimmer, SidebarPushable, SidebarPusher } from "semantic-ui-react";
import Routes from "./Routes";
import { AppContext } from "./Context/AppContext";
import Header from "./components/cmp-header/Header";
import Footer from "./components/cmp-footer/Footer";
import Sidebar from "./containers/admin/sidebar/sidebar";
import "semantic-ui-css/semantic.min.css";
import "./App.css";
import SideSliderCart from "./components/cmp-side-slider-cart/cmp-side-slider-cart";
import { transform } from "lodash";
import AppRoute from "./components/cmp-app-route/AppRoute";
import Buildingheatmapreport from "./containers/customer/customer-home/buildingheatmap-report/buildingheatmap-report";

function App() {
  /* VARIABLE DECLARATIONS
	---------------------------------------------------------------------------------*/
  const { userInfo, bInfo, sliderCart } = useContext(AppContext);
  const [user, setUser] = userInfo;
  const [building_info, setBuildingInfo] = bInfo;
  const [var_is_open, set_is_open] = useState(false);
  const [enable_selection, set_enable_selection] = useState(false);
  const [var_window_size, set_window_size] = useState(0);
  const screenWidth = window.innerWidth;
  const [sideSliderCart] = sliderCart;
  const history = useHistory();
  const location = useLocation();

  /* EVENT LISTENERS
	---------------------------------------------------------------------------------*/
  useEffect(() => {
    return history.listen((location) => {
      set_is_open(false);
    });
  }, [history]);

  useEffect(() => {
    const handleResize = () => {
      set_window_size(screenWidth);
      if (var_window_size > 767) {
        set_is_open(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [var_window_size, screenWidth]);

  /* EVENT FUNCTIONS
	---------------------------------------------------------------------------------*/
  const toggle = () => {
    set_is_open(!var_is_open);
  };

  const dimmerHide = () => {
    set_is_open(false);
  };

  /* RENDER APP
	---------------------------------------------------------------------------------*/
  return (
    <>
       {location.pathname !== '/buildingheatmapreport' &&<SidebarPushable style={{ overflow: "hidden", transform: "none" }}>
        <SideSliderCart visible={sideSliderCart} />
        <SidebarPusher>
          {user ? (
            <div className="app-wrapper">
              <Header
                user={user}
                setUser={setUser}
                building_info={building_info}
                setBuildingInfo={setBuildingInfo}
                navToggle={toggle}
              />
              <main
                className={
                  "app-main" + (user.role === "admin" ? " app-main-admin" : "")
                }
              >
                <div className="app-container">
                  {user.role === "admin" && (
                    <>
                      <Dimmer
                        className="mobile-nav-dimmer"
                        active={var_is_open}
                        onClickOutside={() => dimmerHide()}
                        page
                        inverted
                        blurring
                      />

                      <Sidebar navOpen={var_is_open} />
                    </>
                  )}

                  <Routes appProps={{}} />
                </div>
              </main>

              <Footer />
            </div>
          ) : (
            <Routes appProps={{}} />
          )}
        </SidebarPusher>
      </SidebarPushable>}
      {location.pathname === '/buildingheatmapreport' && 
      <Switch>
        <Route component={Buildingheatmapreport}/>
      </Switch>
      }
    </>
  );
}

export default App;
